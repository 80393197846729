<template>
    <item-list-view
        :api="api"
        :headers="headers"
        title="Врачи"
        search-placeholder="Поиск по ФИО"
        :allowed-search="true"
        :allowed-create="false"
        :allowed-remove="false"
        update-route="Doctor"
    >
    </item-list-view>
</template>

<script>
import api from '../../services/doctors'
import ItemListView from '../../components/ItemListView'

export default {
  components: { ItemListView },
  data: () => ({
    api: api,
    headers: [
      { text: 'ФИО', value: 'fullName' },
      { text: '', value: 'action' }
    ]
  })
}
</script>
